import {createClient} from 'contentful';

import config from '../config';

export function fetchData() {
    return new Promise((resolve, reject) => {
        const client = createClient(config.contentful);

        client.getEntries().then(response => {
            const data = {
                terraceTypes: [],
                authorizations: [],
            };

            (response.items || []).filter(item => item.sys.contentType.sys.id === 'terrace').forEach(terraceData => {
                const terraceType = {
                    ...terraceData.fields,
                    id: terraceData.sys.id,
                    supplies: (response.items || [])
                        .filter(item => item.sys.contentType.sys.id === 'supply' && item.fields.terraces.find(terracesField => terracesField.sys.id === terraceData.sys.id))
                        .map(supplyData => ({
                            ...supplyData.fields,
                            id: supplyData.sys.id,
                            terraces: (supplyData.fields.terraces || []).map(terraceField => terraceField.sys.id)
                        }))
                };
                data.terraceTypes.push(terraceType);
            });

            (response.items || []).filter(item => item.sys.contentType.sys.id === 'auth').forEach(authData => {
                data.authorizations.push(authData.fields.key);
            });

            resolve(data);
        }).catch(reject)
    })
}
