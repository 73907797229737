import React, { Component } from 'react';
import { Container, Row, Col, Card, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Input, Button, ListGroup, ListGroupItem, Alert } from 'reactstrap';

import { Parser } from 'expr-eval';
import { nanoid } from 'nanoid';

import { fetchData } from './util/data';

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            canLaunch: false,
            data: {},
            terraces: [],
        }
    }

    componentDidMount() {
        this.init();
    }

    init = () => {
        const query = new URLSearchParams(window.location.search);
        fetchData().then(data => {
            // console.log(data);
            this.setState({
                data,
                isLoading: false,
                canLaunch: data.authorizations.includes(query.get('pass'))
            });
        }).catch(console.error)
    }

    calculateSupplyArea = (formula, values) => {
        try {
            return Parser.evaluate(formula.replace('{', '').replace('}', ''), values);
        } catch(err) {
            console.error(err);
            return 'ERR';
        }
    }

    getNewTerraceArea = () => ({
        id: nanoid(10),
        squareMeters: 0,
        linearMeters: 0,
    })

    handleAddTerrace = () => {
        this.setState({
            terraces: [
                ...this.state.terraces,
                {
                    id: nanoid(10),
                    typeId: '',
                    name: '',
                    areas: [this.getNewTerraceArea()],
                }
            ]
        })
    }

    handleChangeTerrace = (event, id) => {
        const {target} = event;
        const {name, value} = target;
        this.setState({
            terraces: this.state.terraces.map(terrace => {
                if(terrace.id === id) {
                    return {
                        ...terrace,
                        [name]: value,
                    };
                }
                return terrace;
            })
        })
    }

    handleRemoveTerrace = terraceId => {
        this.setState({
            terraces: this.state.terraces.filter(terrace => terrace.id !== terraceId)
        })
    }

    handleAddTerraceArea = terraceId => {
        this.setState({
            terraces: this.state.terraces.map(terrace => {
                if(terrace.id === terraceId) {
                    return {
                        ...terrace,
                        areas: [
                            ...(terrace.areas || []),
                            this.getNewTerraceArea()

                        ]
                    }
                }
                return terrace
            }),
        })
    }

    handleChangeTerraceArea = (event, terraceId, areaId) => {
        const {target} = event;
        const {name, value} = target;
        this.setState({
            terraces: this.state.terraces.map(terrace => {
                if(terrace.id === terraceId) {
                    return {
                        ...terrace,
                        areas: terrace.areas.map(area => {
                            if(area.id === areaId) {
                                return {
                                    ...area,
                                    [name]: value,
                                }
                            }
                            return area;
                        })
                    }
                }
                return terrace
            }),
        })
    }

    renderForm = () => {
        return (
            <div className="mt-3 no-print">
                {this.state.terraces.map((terrace, index) => (
                    <Card body key={terrace.id} className="mb-3">
                        <Row>
                            <Col xs="12" md="5">
                                <FormGroup>
                                    <Input type="select" name="typeId" value={terrace.typeId} onChange={event => this.handleChangeTerrace(event, terrace.id)}>
                                        <option value="">Choisissez un type de terrasse</option>
                                        {this.state.data.terraceTypes.map(terraceType => <option key={terraceType.id} value={terraceType.id} disabled={this.state.terraces.find(existingTerrace => existingTerrace.typeId === terraceType.id)}>{terraceType.name}</option>)}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="5">
                                <FormGroup>
                                    <Input type="text" name="name" value={terrace.name} placeholder="Entrez le nom de la terrasse" onChange={event => this.handleChangeTerrace(event, terrace.id)} />
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="2" className="text-right">
                                <Button color="link" className="text-danger" onClick={() => this.handleRemoveTerrace(terrace.id)}>Supprimer</Button>
                            </Col>
                        </Row>
                        {terrace.typeId && (
                            <Row>
                                <Col md="5" />
                                <Col>
                                    {terrace.areas.map((area, areaIndex) => (
                                        <Row key={area.id}>
                                            <Col>
                                                <InputGroup>
                                                    <Input type="number" name="squareMeters" value={area.squareMeters} min="0" placeholder="m2" onChange={event => this.handleChangeTerraceArea(event, terrace.id, area.id)} />
                                                    <InputGroupAddon addonType="append"><InputGroupText>m<sup>2</sup></InputGroupText></InputGroupAddon>
                                                </InputGroup>
                                            </Col>
                                            <Col>
                                                <InputGroup>
                                                    <Input type="number" name="linearMeters" value={area.linearMeters} min="0" placeholder="ml" onChange={event => this.handleChangeTerraceArea(event, terrace.id, area.id)} />
                                                <InputGroupAddon addonType="append"><InputGroupText>ml</InputGroupText></InputGroupAddon>
                                            </InputGroup>
                                            </Col>
                                        </Row>
                                    ))}
                                    <Row>
                                        <Col className="text-right">
                                            <Button color="link" size="sm" onClick={() => this.handleAddTerraceArea(terrace.id)}>+ Ajouter une zone</Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="2" />
                            </Row>
                        )}
                    </Card>
                ))}
                <div className="text-center">
                    <Button color="success" outline size="sm" onClick={this.handleAddTerrace}>+ Ajouter une terrasse</Button>
                </div>
            </div>
        )
    }

    renderResults = () => {
        if(this.state.terraces.length) {
            return (
                <Container className="mt-3">
                    {this.state.terraces.map(this.renderTerraceResult)}
                    <div className="d-flex justify-content-between">
                        <h3 className="text-success">Résultats</h3>
                        <Button color="link" className="text-secondary no-print" onClick={() => window.print()}>Imprimer</Button>
                    </div>
                    {this.renderTerracesResult()}
                </Container>
            );
        }
    }

    renderTerraceResult = (terrace) => {
        const terraceType = this.state.data.terraceTypes.find(terraceType => terraceType.id === terrace.typeId) || {};
        const supplies = (terraceType.supplies || []).sort((supplyA, supplyB) => supplyA.sort - supplyB.sort);
        const squareMetersTotal = (terrace.areas || []).reduce((total, area) => total + parseFloat(area.squareMeters || 0), 0);
        const linearMetersTotal = (terrace.areas || []).reduce((total, area) => total + parseFloat(area.linearMeters || 0), 0);
        if(squareMetersTotal || linearMetersTotal) {
            return (
                <div key={terrace.name} className="mb-3 pb-3 border-bottom">
                    <h2 className="d-inline-block">{terrace.name}</h2>
                    {terrace.name && <span> - </span>}<em className="text-secondary">{terraceType.name}</em>
                    <Row>
                        <Col xs="12" md="4">
                            <ListGroup className="mb-3">
                                {(terrace.areas || []).map((area, index) => (
                                    <ListGroupItem key={index} className="d-flex justify-content-between">
                                        <strong>{parseFloat(area.squareMeters)}m<sup>2</sup></strong>
                                        <span>{parseFloat(area.linearMeters)}ml</span>
                                    </ListGroupItem>
                                ))}
                                <ListGroupItem color="light">
                                    <h5>Total</h5>
                                    <div className="d-flex justify-content-between">
                                        <strong>{squareMetersTotal}m<sup>2</sup></strong>
                                        <span>{linearMetersTotal}ml</span>
                                    </div>
                                </ListGroupItem>
                            </ListGroup>
                        </Col>
                        <Col>
                            {
                                supplies.length > 0
                                ? (
                                    <ListGroup>
                                        {supplies.map(supply => this.renderSupply(supply, squareMetersTotal, linearMetersTotal))}
                                    </ListGroup>
                                ) : (
                                    <Alert color="danger">
                                        <h6>Aucune fourniture associée à ce type de terrasse !</h6>
                                        Veuillez contrôler la configuration du type de terrasse.
                                    </Alert>
                                )
                            }
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    renderTerracesResult = () => {
        const terraceTypes = this.state.terraces.reduce((types, terrace) => types.concat(this.state.data.terraceTypes.find(terraceType => terraceType.id === terrace.typeId) || {}), []);
        const supplies = terraceTypes.reduce((supplies, terraceType) => {
            const supplyToAdd = [];
            (terraceType.supplies || []).forEach(supply => {
                if(!supplies.find(existingSupply => supply.id === existingSupply.id)) {
                    supplyToAdd.push({
                        ...supply,
                        squareMetersTotal: this.state.terraces.filter(terrace => supply.terraces.includes(terrace.typeId)).reduce((total, terrace) => total + terrace.areas.reduce((totalArea, area) => totalArea + parseFloat(area.squareMeters || 0), 0), 0),
                        linearMetersTotal: this.state.terraces.filter(terrace => supply.terraces.includes(terrace.typeId)).reduce((total, terrace) => total + terrace.areas.reduce((totalArea, area) => totalArea + parseFloat(area.linearMeters || 0), 0), 0),
                    });
                }
            })
            return supplies.concat((supplyToAdd || []));
        }, []).sort((supplyA, supplyB) => supplyA.sort - supplyB.sort);

        console.log(this.state.terraces, supplies);

        const areas = this.state.terraces.reduce((areas, terrace) => areas.concat(terrace.areas), []);
        const squareMetersTotal = (areas || []).reduce((total, area) => total + parseFloat(area.squareMeters || 0), 0);
        const linearMetersTotal = (areas || []).reduce((total, area) => total + parseFloat(area.linearMeters || 0), 0);


        if(squareMetersTotal && linearMetersTotal && this.state.terraces.length && supplies.length) {
            return (
                <div className="mb-3 pb-3 border-bottom">
                    <Row>
                        <Col xs="12" md="4">
                            <ListGroup className="mb-3">
                                <ListGroupItem color="light">
                                    <h5>Total</h5>
                                    <div className="d-flex justify-content-between">
                                        <strong>{squareMetersTotal}m<sup>2</sup></strong>
                                        <span>{linearMetersTotal}ml</span>
                                    </div>
                                </ListGroupItem>
                            </ListGroup>
                        </Col>
                        <Col>
                            {
                                supplies.length > 0
                                ? (
                                    <ListGroup>
                                        {supplies.map(supply => this.renderSupply(supply, supply.squareMetersTotal, supply.linearMetersTotal, true))}
                                    </ListGroup>
                                ) : (
                                    <Alert color="danger">
                                        <h6>Aucune fourniture associée à ce type de terrasse !</h6>
                                        Veuillez contrôler la configuration du type de terrasse.
                                    </Alert>
                                )
                            }
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    renderSupply = (supply, surface, perimeter, isFinalResult = false) => {
        console.log(supply);
        const supplySurface = this.calculateSupplyArea(supply.surfaceCalculation, {surface, perimeter});
        const resultBrut = supplySurface / supply.value;
        const resultNet = Math.ceil(resultBrut);
        return (
            <ListGroupItem>
                <Row key={supply.id} title={`${supply.surfaceCalculation} = ${supply.surfaceCalculation.replace('{surface}', surface).replace('{perimeter}', perimeter)} = ${Math.round(resultBrut*100)/100}`}>
                    <Col className="text-right">
                        <strong className={`h5 ${isFinalResult ? 'text-success' : ''}`}>{resultNet}</strong>
                    </Col>
                    <Col>
                        <h5>{supply.name}</h5>
                    </Col>
                    <Col>
                        {Math.round(supplySurface*100)/100}{supply.unit} / {supply.value}{supply.unit}
                    </Col>
                    <Col>
                        <small><em>{Math.round(resultBrut*100)/100}</em></small>
                    </Col>
                </Row>
            </ListGroupItem>
        )
    }

    renderContent = () => {
        if(this.state.isLoading) {
            return <span className="d-block text-center">Loading...</span>
        }
        return (
            <Container>
                {this.renderForm()}
                {this.renderResults()}
            </Container>
        );
    }

    renderAuth = () => {
        return (
            <Alert color="warning" className="text-center">
                <h4>Vous n'avez pas accès à cet outil !</h4>
                Veuillez contacter votre responsable...
            </Alert>
        );
    }

    render() {
        return (
            <Container>
                <div className="my-3 text-center">
                    <h1 className="text-danger">P.B.Plus</h1>
                    <h2 className="text-secondary">Calcul de fournitures de terrasse</h2>
                </div>
                {   this.state.canLaunch
                    ? this.renderContent()
                    : this.renderAuth()
                }
            </Container>
        )
    }
}

export default App;
